import { Container, Grid, makeStyles } from "@material-ui/core"
import React from "react"
import FormTextField from "../../../FormTextField"
import NormalSelect from "../../../SelectWithImage/NormalSelect"
import flag from "../../../../../images/flagIcon.svg"
import SelectWithIcon from "../../../SelectWithImage/SelectWithIcon"
import { actions } from "../../../../../redux/slices/clubSlice"
import { useSelector, useDispatch } from "react-redux"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "transprent",
    width: "90%",
    borderRadius: "10px 10px 0px 0px",
    height: "100%",
    paddingTop: "10px",
    fontFamily: "DM Sans",
  },
}))

function AddBillingItems({ validation, errors, setFormsErrors }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const state = useSelector(state => state.clubReducer.billingInfo)

  const handleChange = (key, value) => {
    let temp = { ...errors.billingInfo }

    delete temp[key]

    setFormsErrors({ ...errors, billingInfo: temp })
    dispatch(actions.clubInfo({ key1: "billingInfo", key2: key, value }))
    //validateFields(formData);
  }

  const DATA_COUNTRY = [
    { label: "Tennis", value: 1 },
    { label: "Football", value: 2 },
    { label: "Cricket", value: 3 },
  ]

  const DATA_BANK = [
    { label: "SwedBank", value: 1 },
    { label: "Football", value: 2 },
    { label: "Cricket", value: 3 },
  ]

  const DATA_PRICE = [
    { label: "SE", value: 1, icon: flag },
    { label: "SE", value: 2, icon: flag },
    { label: "SE", value: 3, icon: flag },
  ]

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="space-arround"
          alignContent="space-between"
          spacing="4"
        >
          <Grid xs={6} style={{ paddingRight: "10px", position: "relative" }}>
            <FormTextField
              errors={errors["billingInfo"]?.companyName}
              onChange={e => handleChange("companyName", e.target.value)}
              value={state.companyName}
              placeHolder="Company Name"
              label="Company Name"
            />
            <FormTextField
              errors={errors["billingInfo"]?.vatNumber}
              onChange={e => handleChange("vatNumber", e.target.value)}
              value={state.vatNumber}
              placeHolder="VAT number"
              label="VAT number"
            />
            <Grid
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
                marginBottom: "1px",
              }}
            >
              <div style={{ width: "100%" }}>
                <NormalSelect
                  errors={errors["billingInfo"]?.country}
                  validation={validation}
                  value={state?.country}
                  onChange={value => handleChange("country", value)}
                  data={DATA_COUNTRY}
                  placeHolder="Country"
                  label="Country"
                />
              </div>
              <div style={{ width: "100%" }}>
                <NormalSelect
                  errors={errors["billingInfo"]?.bank}
                  validation={validation}
                  value={state?.bank}
                  onChange={value => handleChange("bank", value)}
                  data={DATA_BANK}
                  placeHolder="Bank"
                  label="Bank"
                />
              </div>
            </Grid>
            <FormTextField
              errors={errors["billingInfo"]?.iban}
              onChange={e => handleChange("iban", e.target.value)}
              value={state.iban}
              placeHolder="IBAN"
              label="IBAN"
            />
            <FormTextField
              errors={errors["billingInfo"]?.bic}
              onChange={e => handleChange("bic", e.target.value)}
              value={state.bic}
              placeHolder="BIC"
              label="BIC"
            />
            <Grid
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  justifyItems: "center",
                  marginRight: "-46px",
                  width: "40%",
                  marginTop: "2px",
                }}
              >
                <SelectWithIcon
                  errors={errors["billingInfo"]?.clubPhone}
                  onChange={e => handleChange("clubPhone", e)}
                  value={state.clubPhone}
                  data={DATA_PRICE}
                  placeHolder="Country"
                  label="ClubPhone"
                />
              </div>
              <FormTextField
                errors={errors["billingInfo"]?.billingAddress}
                onChange={e => handleChange("billingAddress", e.target.value)}
                value={state.billingAddress}
                placeHolder="Address"
                label="Address"
              />
              <FormTextField
                errors={errors["billingInfo"]?.billingPostalCode}
                onChange={e =>
                  handleChange("billingPostalCode", e.target.value)
                }
                value={state.billingPostalCode}
                placeHolder="Postal code"
                label="Postal code"
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default AddBillingItems
